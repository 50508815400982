<template>
  <ion-page>

    
    <ion-content :fullscreen="true">
      <main>
        <div id="firstContainer">
        </div>
        <div id="secondRow">
            <p id="secondContainer">
                Software &nbsp;&nbsp;&nbsp;.&nbsp;&nbsp;&nbsp; Usability
                <br/>Engineering &nbsp;&nbsp;&nbsp;.&nbsp;&nbsp;&nbsp;&nbsp; Cloud
                <br/>Security &nbsp;.&nbsp; Application
                <br/>Development . Hosting
                <br/>Project . Architecture
                <br/>Methodology &nbsp;.&nbsp; PCI-DSS
            </p>
            <div id="seperatorOne"></div>
            <div id="thirdContainer">
                09621
                <span style="font-size: 10px;">/</span> 7788990
                <br/> info@blueits.com
                <br/>
                <br/> BLUEITS GmbH
                <br/> Auf den Hochäckern 5
                <br/> 92224 Amberg
            </div>
            <div id="seperatorTwo"></div>
            <div id="fourthContainer">
                Geschäftsführer:
                <br/> Dietmar Scharf
                <br/>
                <br/> Amtsgericht Amberg
                <br/> HRB 5265
                <br/> DE813025272
            </div>
        </div>
      </main>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar } from '@ionic/vue';

export default {
  name: 'Impressum',
  components: {
    IonContent,
    IonPage
  }
}
</script>

<style scoped>
main {
  margin: auto;
  width: 750px;
  padding: 15px;
  font-size: 14.5px;
}
#firstContainer {
  background-image: url("../../resources/blueits.png");
  width: 748px;
  height: 317px;
  margin-bottom: 15px;
}
#secondContainer {
  width: 196px;
  text-transform: uppercase;
  text-align: justify;
  padding: 0px 15px 15px 0px;
  height: 100px;
  margin: 0px;
  display: inline-block;
}
#thirdContainer {
  width: 180px;
  padding: 0px 15px 15px 0px;
  display: inline-block;
}
#fourthContainer {
  width: 180px;
  padding: 0px 15px 15px 0px;
  display: inline-block;
}
#seperatorOne {
  width: 3px;
  background-color: #3573c2;
  margin-right: 50px;
  margin-top: 3px;
  height: 108px;
  display: inline-block;
  margin-left: 24px;
}
#seperatorTwo {
  width: 3px;
  background-color: #3573c2;
  margin-right: 50px;
  margin-top: 3px;
  height: 108px;
  display: inline-block;
}
</style>